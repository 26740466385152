// Import any custom external reducers here and export below
// They will be merged with base app reducers

// for example
// import adyenReducer from '@some/path/store/reducers/adyen';

// export default { adyenReducer };

import adyen from '@vaimo/adyen-payment-methods/src/store/reducers/adyen';
import emarsys from '@vaimo/emarsys-tracking/src/store/reducers/emarsys';
import gtm from '@vaimo/google-tag-manager/src/store/reducers/gtm';
import loqate from '@vaimo/loqate/src/store/reducers/loqate';
import monetate from '@vaimo/monetate/src/store/reducers/monetate';

const extendReducers = {
    adyen,
    emarsys,
    gtm,
    loqate,
    monetate,
};

export default extendReducers;
