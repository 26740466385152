'use client';

import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { default as reducers } from './reducers';
import { default as enhancers } from './enhancers';
import extendReducers from './reducers/extendReducers';

const rootReducer = {
    ...reducers,
    ...extendReducers,
};

const makeStore = configureStore({
    enhancers: () => [enhancers],
    middleware: () => [],
    reducer: rootReducer,
});

const initStore = () => {
    return makeStore;
};

// Ref: https://github.com/kirill-konshin/next-redux-wrapper
export default makeStore;
export const wrapper = createWrapper(initStore, { debug: true });
