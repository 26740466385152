import { handleActions } from 'redux-actions';
import actions from '../actions/loqate';

// isAddressVerified is set to true by default, because an empty form cannot be submitted anyway
// but if we end up in some address edit form, we don't want to re-verify the existing address
// only if customer started editing it

const initialState = {
    countryFieldId: 'country',
    dialogSuggestions: [],
    formAddress: {},
    isAddressVerified: true,
    isLoqateDialogOpen: false,
    selectedCountryCode: null,
    suggestions: [],
    userSelectedAddress: {},
};

const reducerMap = {
    [actions.setIsLoqateDialogOpen]: (state, { payload }) => ({
        ...state,
        isLoqateDialogOpen: payload,
    }),
    [actions.setCountryFieldId]: (state, { payload }) => ({
        ...state,
        countryFieldId: payload,
    }),
    [actions.setSelectedCountryCode]: (state, { payload }) => ({
        ...state,
        selectedCountryCode: payload,
    }),
    [actions.setDialogSuggestions]: (state, { payload }) => ({
        ...state,
        dialogSuggestions: payload,
    }),
    [actions.setSuggestions]: (state, { payload }) => ({
        ...state,
        suggestions: payload,
    }),
    [actions.setIsAddressVerified]: (state, { payload }) => ({
        ...state,
        isAddressVerified: payload,
    }),
    [actions.setUserSelectedAddress]: (state, { payload }) => ({
        ...state,
        userSelectedAddress: payload,
    }),
    [actions.setFormAddress]: (state, { payload }) => ({
        ...state,
        formAddress: payload,
    }),
    [actions.reset]: () => initialState,
};

export default handleActions(reducerMap, initialState);
