'use client';

import store from '@/lib/store';
import actions from '@/lib/store/actions/toast/actions';
import { ReactElement } from 'react';

// By default, all toasts are dismissed after a timeout unless specified by the
// implementer via `timeout = 0` or `timeout = false`.
const DEFAULT_TIMEOUT = 5000;

/**
 * Generates an identifier for a toast by inspecting the properties that
 * differentiate toasts from one another.
 */
const getToastId = ({ actionText = '', dismissible = true, icon = null, message, variant }: ToastProps) => {
    const combined = [variant, message, dismissible, actionText, icon].join();

    // The hashing function below should generally avoid accidental collisions.
    // It exists to give a "readable" identifier to toasts for debugging.
    let hash = 0;
    let i;
    let chr;
    if (combined.length === 0) return hash;
    for (i = 0; i < combined.length; i++) {
        chr = combined.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash;
};

/**
 * Removes a toast from the toast store.
 */
const remove = (id: number) => {
    // @ts-expect-error No TS set for store
    store.dispatch(actions.remove({ id }));
};

/**
 * Dispatches an add action. Includes all props passed along with a hash id
 * and a timeout id generated based on the incoming props.
 *
 * @returns id The key referencing the toast in the store
 */

export enum ToastEnum {
    default = 'default',
    error = 'error',
    success = 'success',
}

interface ToastProps {
    actionText?: string; // Text to display as a call to action.
    dismissible?: boolean; // Indicates whether the toast is dismissible. If `onDismiss` is provided, this property is assumed to be true. This property is optional when creating toasts.
    icon?: null | ReactElement; // The icon element to display.
    message: string; // The message to display on the toast
    onAction?: (callback: any) => void; // Callback invoked when a user clicks the action
    onDismiss?: (callback: any) => void; // Callback invoked when a user clicks the dismiss icon.
    timeout?: number | boolean; // Time, in ms, before the toast is automatically dismissed. If `0` or `false` is passed, the toast will not timeout.
    variant?: ToastEnum;
}

const add = (toastProps: ToastProps): number => {
    const { dismissible, icon, message, onAction, onDismiss, timeout = DEFAULT_TIMEOUT } = toastProps;

    if (!message) {
        throw new TypeError('toast.message is required');
    }

    if (!(timeout || timeout === 0 || timeout === false) && !(onDismiss || dismissible)) {
        throw new TypeError('Toast should be user-dismissible or have a timeout');
    }

    // Generate the id to use in the removal timeout.
    const id = getToastId(toastProps);

    const handleDismiss = () => {
        onDismiss ? onDismiss(() => remove(id)) : remove(id);
    };

    const handleAction = () => (onAction ? onAction(() => remove(id)) : () => {});

    // A timeout of 0 means no auto-dismiss.
    let removalTimeoutId;
    if (timeout !== 0 && timeout !== false) {
        removalTimeoutId = setTimeout(
            () => {
                handleDismiss();
            },
            (timeout as number) || DEFAULT_TIMEOUT,
        );
    }

    store.dispatch(
        // @ts-expect-error No TS set for store
        actions.add({
            ...toastProps,
            handleAction,
            handleDismiss,
            icon,
            id,
            removalTimeoutId,
            timestamp: Date.now(),
        }),
    );

    return id;
};

const toastApi = { add, remove };
export default toastApi;
