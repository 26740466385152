import { gql } from '@apollo/client';

export const CheckoutPageFragment = gql`
    fragment CheckoutPageFragment on Cart {
        id
        email
        applied_coupons {
            code
        }
        items {
            uid
            item_discount_description {
                discount
                discount_amount
                discount_type
            }
            prices {
                price {
                    currency
                    value
                }
                final_price {
                    currency
                    value
                }
                row_total {
                    currency
                    value
                }
                row_total_including_tax {
                    currency
                    value
                }
                total_item_discount {
                    currency
                    value
                }
                discounts {
                    amount {
                        currency
                        value
                    }
                }
            }
            product {
                name
                uid
                stock_status
                categories {
                    id
                    uid
                    name
                }
                price_range {
                    minimum_price {
                        final_price {
                            currency
                            value
                        }
                        regular_price {
                            currency
                            value
                        }
                    }
                }
                sku
                ... on ConfigurableProduct {
                    variants {
                        attributes {
                            uid
                        }
                        product {
                            id
                            uid
                            small_image {
                                url
                            }
                        }
                    }
                }
            }
            quantity
            ... on ConfigurableCartItem {
                configurable_options {
                    id
                    configurable_product_option_value_uid
                    option_label
                    value_id
                    value_label
                }
                configured_variant {
                    id
                    uid
                    sku
                }
            }
        }
        selected_payment_method {
            code
            title
        }
        shipping_addresses {
            selected_shipping_method {
                carrier_title
                method_title
                amount {
                    currency
                    value
                }
            }
            street
        }
        total_quantity
    }
`;

export const CheckoutTotalFragment = gql`
    fragment CheckoutTotalFragment on Cart {
        total_quantity
        prices {
            grand_total {
                currency
                value
            }
        }
    }
`;
