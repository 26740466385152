import { handleActions } from 'redux-actions';
import actions from '../actions/adyen';

const initialState = {
    card: null,
    checkout: null,
    detailsState: null,
    error: null,
    submitState: null,
};

const reducerMap = {
    [actions.setCard]: (state, { payload }) => {
        return {
            ...state,
            card: payload,
        };
    },
    [actions.resetCard]: (state) => {
        return {
            ...state,
            card: null,
        };
    },
    [actions.setCheckout]: (state, { payload }) => {
        return {
            ...state,
            checkout: payload,
        };
    },
    [actions.resetCheckout]: (state) => {
        return {
            ...state,
            checkout: null,
        };
    },
    [actions.setDetails]: (state, { payload }) => {
        return {
            ...state,
            detailsState: payload,
        };
    },
    [actions.resetDetails]: (state) => {
        return {
            ...state,
            detailsState: null,
        };
    },
    [actions.submit]: (state, { payload }) => {
        return {
            ...state,
            submitState: payload,
        };
    },
    [actions.resetSubmit]: (state) => {
        return {
            ...state,
            submitState: null,
        };
    },
    [actions.error]: (state, { payload }) => {
        return {
            ...state,
            error: payload,
        };
    },
    [actions.resetError]: (state) => {
        return {
            ...state,
            error: null,
        };
    },
    [actions.onChange]: (state, { payload }) => {
        return {
            ...state,
            ...(!payload.errors && { submitState: payload }),
        };
    },
};

export default handleActions(reducerMap, initialState);
