import { gql } from '@apollo/client';
import { DiscountSummaryFragment } from '@/talons/PriceSummary/discountSummary.gql';
import { TaxSummaryFragment } from '@/talons/PriceSummary/taxSummary.gql';
import { AppliedStoreCreditFragment } from '@/components/AccountStoreCredit/api/appliedStoreCreditFragment.gql';

export const OrderConfirmationItemsReviewFragment = gql`
    fragment OrderConfirmationItemsReviewFragment on Cart {
        id
        total_quantity
        items {
            id
            uid
            item_discount_description {
                discount
                discount_amount
                discount_type
            }
            prices {
                row_total {
                    currency
                    value
                }
                row_total_including_tax {
                    currency
                    value
                }
                total_item_discount {
                    currency
                    value
                }
                final_price {
                    currency
                    value
                }
            }
            product {
                uid
                name
                categories {
                    id
                    uid
                    name
                }
                thumbnail {
                    url
                }
                price_range {
                    minimum_price {
                        final_price {
                            currency
                            value
                        }
                        regular_price {
                            currency
                            value
                        }
                    }
                }
                sku
                ... on ConfigurableProduct {
                    bm_category
                    configurable_options {
                        id
                        attribute_code
                        uid
                    }
                }
                ... on ConfigurableProduct {
                    variants {
                        attributes {
                            uid
                        }
                        product {
                            id
                            uid
                            thumbnail {
                                url
                            }
                        }
                    }
                }
            }
            quantity
            ... on ConfigurableCartItem {
                configurable_options {
                    configurable_product_option_uid
                    option_label
                    configurable_product_option_value_uid
                    value_label
                }
                configured_variant {
                    id
                    uid
                    sku
                }
            }
        }
    }
`;

export const OrderConfirmationPageFragment = gql`
    fragment OrderConfirmationPageFragment on Cart {
        applied_coupons {
            code
        }
        id
        email
        total_quantity
        shipping_addresses {
            firstname
            lastname
            street
            city
            region {
                label
            }
            postcode
            country {
                label
            }
            telephone
            vat_id
            selected_shipping_method {
                carrier_title
                method_title
                amount {
                    currency
                    value
                }
                shipping_discount_amount {
                    currency
                    value
                }
            }
        }
        billing_address {
            firstname
            lastname
            street
            city
            region {
                label
            }
            postcode
            country {
                label
            }
            telephone
            vat_id
        }
        selected_payment_method {
            brand_code
            code
            title
        }
        prices {
            base_currency_revenue {
                currency
                value
            }
            discount_breakdown {
                label
                amount
            }
            subtotal_excluding_tax {
                currency
                value
            }
            subtotal_including_tax {
                currency
                value
            }
            grand_total {
                currency
                value
            }
            ...TaxSummaryFragment
            ...DiscountSummaryFragment
        }
        ...AppliedStoreCreditFragment
        ...OrderConfirmationItemsReviewFragment
    }
    ${OrderConfirmationItemsReviewFragment}
    ${TaxSummaryFragment}
    ${DiscountSummaryFragment}
    ${AppliedStoreCreditFragment}
`;

export const CANCEL_ORDER = gql`
    mutation cancelOrder($orderNumber: String!, $protectCode: String) {
        requestOrderCancel(orderNumber: $orderNumber, protectCode: $protectCode)
    }
`;

export const GET_CURRENCY_RATE = gql`
    query getCurrencyRate {
        rate
    }
`;
