import React, { ReactElement } from 'react';
import Select from './select';
import StoreSwitcherTrigger from '@/components/StoreSwitcher/modules/StoreSwitcherTrigger';

import type { ISelectWithStoreSwitcherProps } from './types';
import classes from './select.module.scss';

const SelectWithStoreSwitcher = ({
    canSwitchStore,
    onStoreSwitchClick,
    ...rest
}: ISelectWithStoreSwitcherProps): ReactElement => (
    <div className={classes.root}>
        <Select {...rest} />
        {canSwitchStore && (
            <div className={classes.storeSwitcherWrapper}>
                <StoreSwitcherTrigger triggerVariant={'secondary'} isSmall onClick={onStoreSwitchClick} />
            </div>
        )}
    </div>
);

SelectWithStoreSwitcher.displayName = 'SelectWithStoreSwitcher';

export default SelectWithStoreSwitcher;
