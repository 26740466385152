import { applyMiddleware } from 'redux';

import auth from '@/lib/store/middleware/auth';
import log from '@/lib/store/middleware/log';
import thunk from '@/lib/store/middleware/thunk';

const middleware = [thunk, auth];

if (process.env.NODE_ENV !== 'production') {
    middleware.push(log);
}

export default applyMiddleware(...middleware);
