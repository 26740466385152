import shrinkQuery from '@/lib/Apollo/utils/shrinkQuery';

/**
 * Intercept and shrink URLs from GET queries.
 *
 * Using GET makes it possible to use edge caching in Magento Cloud, but risks
 * exceeding URL limits with default usage of Apollo's http link.
 *
 * `shrinkQuery` encodes the URL in a more efficient way.
 *
 */

const customFetchClient = async (uri: string, options: any) => {
    options.headers.accept = 'application/json';
    const resource = options.method === 'GET' ? shrinkQuery(uri) : uri;

    return fetch(resource, options);
};

export default customFetchClient;
