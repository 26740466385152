import { handleActions } from 'redux-actions';

import actions from '@/lib/store/actions/cart';

export const name = 'cart';

export const initialState = {
    addItemError: null,
    cartContent: null,
    cartId: null,
    details: {},
    detailsError: null,
    getCartError: null,
    isAddingItem: false,
    isLoading: false,
    isShippingMethodUpdated: false,
    isUpdatingItem: false,
    removeItemError: null,
    shippingMethods: [],
    uniqueConfigurableSkusInCart: null,
    updateItemError: null,
};

const reducerMap = {
    [actions.getCart.request]: (state) => ({
        ...state,
        isLoading: true,
    }),
    [actions.getCart.receive]: (state, { error, payload }) => {
        if (error) {
            return {
                ...initialState,
                getCartError: payload,
                isLoading: false,
            };
        }

        return {
            ...state,
            cartId: String(payload),
            getCartError: null,
            isLoading: false,
        };
    },
    [actions.getDetails.request]: (state) => ({
        ...state,
        isLoading: true,
    }),
    [actions.getDetails.receive]: (state, { error, payload }) => {
        if (error) {
            return {
                ...state,
                detailsError: payload,
                isLoading: false,
            };
        }

        return {
            ...state,
            // The only time we should spread the payload into the cart store
            // is after we've fetched cart details.
            ...payload,
            isLoading: false,
        };
    },
    [actions.addItem.request]: (state) => ({
        ...state,
        isAddingItem: true,
        isLoading: true,
    }),
    [actions.addItem.receive]: (state, { error, payload }) => {
        if (error) {
            return {
                ...state,
                addItemError: payload,
                isAddingItem: false,
                isLoading: false,
            };
        }

        return {
            ...state,
            isAddingItem: false,
            isLoading: false,
        };
    },
    [actions.updateItem.request]: (state) => ({
        ...state,
        isLoading: true,
        isUpdatingItem: true,
    }),
    [actions.updateItem.receive]: (state, { error, payload }) => {
        if (error) {
            return {
                ...state,
                isLoading: false,
                isUpdatingItem: false,
                updateItemError: payload,
            };
        }

        // We don't actually have to update any items here
        // because we force a refresh from the server.
        return {
            ...state,
            isLoading: false,
            isUpdatingItem: false,
        };
    },
    [actions.removeItem.receive]: (state, { error, payload }) => {
        if (error) {
            return {
                ...state,
                isLoading: false,
                removeItemError: payload,
            };
        }

        return {
            ...state,
            isLoading: false,
        };
    },
    [actions.setCartContent]: (state, { payload }) => ({
        ...state,
        cartContent: payload,
        uniqueConfigurableSkusInCart: new Set((payload.cart?.items || []).map(({ product }) => product.sku)),
    }),
    [actions.setShippingMethodUpdated]: (state, { payload }) => ({
        ...state,
        isShippingMethodUpdated: payload,
    }),
    [actions.reset]: () => initialState,
};

export default handleActions(reducerMap, initialState);
