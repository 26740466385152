import { createActions } from 'redux-actions';

const prefix = 'LOQATE';
const actionTypes = [
    'RESET',
    'SET_DIALOG_SUGGESTIONS',
    'SET_IS_ADDRESS_VERIFIED',
    'SET_COUNTRY_FIELD_ID',
    'SET_FORM_ADDRESS',
    'SET_IS_LOQATE_DIALOG_OPEN',
    'SET_SELECTED_COUNTRY_CODE',
    'SET_SUGGESTIONS',
    'SET_USER_SELECTED_ADDRESS',
];

export default createActions(...actionTypes, { prefix });
