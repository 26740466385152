import { handleActions } from 'redux-actions';
import actions from '../actions/gtm';

const initialState = {
    cartData: null,
    isSignedIn: false,
    referrerPath: '',
};

const reducerMap = {
    [actions.setCartData]: (state, { payload }) => ({
        ...state,
        cartData: payload,
    }),
    [actions.resetCartData]: (state) => ({
        ...state,
        cartData: null,
    }),
    [actions.setIsSignedIn]: (state, { payload }) => ({
        ...state,
        isSignedIn: payload,
    }),
    [actions.setReferrerPath]: (state, { payload }) => ({
        ...state,
        referrerPath: payload,
    }),
};
export default handleActions(reducerMap, initialState);
