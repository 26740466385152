import { handleActions } from 'redux-actions';
import actions from '../actions/emarsys';

const initialState = {
    cartData: null,
    currentUserEmail: '',
    previousPath: '',
};

const reducerMap = {
    [actions.setCartData]: (state, { payload }) => ({
        ...state,
        cartData: payload,
    }),
    [actions.setCurrentUserEmail]: (state, { payload }) => ({
        ...state,
        currentUserEmail: payload,
    }),
    [actions.resetCartData]: (state) => ({
        ...state,
        cartData: null,
    }),
    [actions.resetCurrentUserEmail]: (state) => ({
        ...state,
        currentUserEmail: null,
    }),
    [actions.setPreviousPath]: (state, { payload }) => ({
        ...state,
        previousPath: payload,
    }),
};

export default handleActions(reducerMap, initialState);
