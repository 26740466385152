import { useEffect } from 'react';
import { useAppContext } from '@/lib/context';
import useStoreCodeInUrl from '@/hooks/useStoreCodeInUrl';
import { useStoreSwitcherForm } from '../modules/StoreSwitcherForm/hooks/useStoreSwitcherForm';
import { getCookie, isCookieSet } from '@/utils/cookieManager';
import type { IUseStoreSwitcher } from '../types';
import { GEOLOCATION_STORE_COOKIE } from '@/constants/cookies';
import { useStoreSwitcherContext } from '@/components/StoreSwitcher/context/storeSwitcherContext';

export const useStoreSwitcher = (): IUseStoreSwitcher => {
    const { resolvedStoreGeolocationCode } = useStoreSwitcherContext();

    const [{ isStoreSwitcherOpen, storeConfig }] = useAppContext();
    const { storeCode } = storeConfig;
    const storeCodeInURL = useStoreCodeInUrl();

    const { handleGeolocationPopup, handleGeolocationPopupCancel, setDefaultSwitcherVariant, switcherVariant } =
        useStoreSwitcherForm();

    useEffect(() => {
        if (!resolvedStoreGeolocationCode) return;

        const isStoreCookieSet = isCookieSet(GEOLOCATION_STORE_COOKIE);
        const savedStoreCookieValue = getCookie(GEOLOCATION_STORE_COOKIE);

        // For appropriate scenario mapping, @see https://jira.vaimo.com/browse/HLHL-693
        // Scenarios when no action is required, and user is using correct store from the get go: 1b, 2a, 2b, 3c
        // Scenarios handled on load in Adapter: 1a

        if (!isStoreCookieSet) {
            // scenario 1c
            if (resolvedStoreGeolocationCode !== storeCodeInURL)
                return handleGeolocationPopup(storeCodeInURL, resolvedStoreGeolocationCode);
        }

        // scenario 2c
        if (
            storeCodeInURL &&
            storeCodeInURL !== savedStoreCookieValue &&
            storeCodeInURL !== resolvedStoreGeolocationCode &&
            savedStoreCookieValue === resolvedStoreGeolocationCode
        ) {
            handleGeolocationPopup(storeCodeInURL, resolvedStoreGeolocationCode);
        }

        // scenario 3a
        if (!storeCodeInURL && savedStoreCookieValue !== resolvedStoreGeolocationCode) {
            handleGeolocationPopup(savedStoreCookieValue, resolvedStoreGeolocationCode);
        }

        // scenario 3b + 3d
        if (
            storeCodeInURL &&
            storeCodeInURL !== savedStoreCookieValue &&
            savedStoreCookieValue !== resolvedStoreGeolocationCode
        ) {
            handleGeolocationPopup(storeCodeInURL, savedStoreCookieValue, true);
        }
    }, [resolvedStoreGeolocationCode]);

    const shouldHideSwitcher = storeCode === 'en_global' || !isStoreSwitcherOpen;

    return {
        handleGeolocationPopupCancel,
        setDefaultSwitcherVariant,
        shouldHideSwitcher,
        switcherVariant,
    };
};
