import { handleActions } from 'redux-actions';

import actions from '@/lib/store/actions/app';
export const name = 'app';

// @todo hasBeenOffline and isOnline logic for ServiceWorkers. Needs to check and refactor
// as far as the server is concerned, the app is always online
// const isServer = !globalThis.navigator;
// const isOnline = !isServer && navigator.onLine;
// const hasBeenOffline = !isServer && !navigator.onLine;

const initialState = {
    addToCartActionStatus: 'DEFAULT',
    addToCartSuccessData: null,
    drawer: null,
    // hasBeenOffline,
    isFilterModalOpen: false,
    isFilterSidebarOpen: true,
    // isOnline,
    isPageLoading: true,
    isSearchOpen: false,
    isStoreSwitcherOpen: false,
    loadingBarProgress: 0,
    nextRootComponent: null,
    overlay: false,
    pending: {},
    shouldPurgePreviousItems: false,
    storeSwitcherLocation: 'DEFAULT',
};

const reducerMap = {
    [actions.toggleDrawer]: (state, { payload }) => {
        return {
            ...state,
            drawer: payload,
            overlay: payload === 'filter' ? false : !!payload,
        };
    },
    // [actions.setOnline]: (state) => {
    //     return {
    //         ...state,
    //         isOnline: true,
    //     };
    // },
    // [actions.setOffline]: (state) => {
    //     return {
    //         ...state,
    //         hasBeenOffline: true,
    //         isOnline: false,
    //     };
    // },
    [actions.setPageLoading]: (state, { payload }) => {
        return {
            ...state,
            isPageLoading: !!payload,
        };
    },
    [actions.setLoadingBarProgress]: (state, { payload }) => {
        return {
            ...state,
            loadingBarProgress: payload,
        };
    },
    [actions.setNextRootComponent]: (state, { payload }) => {
        return {
            ...state,
            nextRootComponent: payload,
        };
    },
    [actions.setIsSearchOpen]: (state, { payload }) => ({
        ...state,
        isSearchOpen: payload,
    }),
    [actions.toggleSearch]: (state) => ({
        ...state,
        isSearchOpen: !state.isSearchOpen,
    }),
    [actions.setIsStoreSwitcherOpen]: (state, { payload }) => ({
        ...state,
        isStoreSwitcherOpen: payload.isOpen,
        storeSwitcherLocation: payload.location || 'CENTER',
    }),
    [actions.setShouldPurgePreviousItems]: (state, { payload }) => {
        return state.shouldPurgePreviousItems === payload
            ? state
            : {
                  ...state,
                  shouldPurgePreviousItems: payload,
              };
    },
    [actions.setAddToCartSuccessData]: (state, { payload }) => {
        return state.addToCartSuccessData === payload
            ? state
            : {
                  ...state,
                  addToCartSuccessData: payload,
              };
    },
    [actions.setAddToCartActionStatus]: (state, { payload }) => {
        return {
            ...state,
            addToCartActionStatus: payload,
        };
    },
    [actions.setIsFilterSidebarOpen]: (state, { payload }) => {
        return {
            ...state,
            isFilterSidebarOpen: payload,
        };
    },
    [actions.setIsFilterModalOpen]: (state, { payload }) => {
        return {
            ...state,
            isFilterModalOpen: payload,
        };
    },
};

export default handleActions(reducerMap, initialState);
