import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@vaimo-int+aem-pwa-common-components@4.0.14_@types+node@20.11.30_babel-jest@29.7.0_postcss@8._7crfax4mz73bfdobr4myioix2i/node_modules/@vaimo-int/aem-pwa-common-components/dist/styles/designsystem.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.10_next@14.2.10_react@18.2.0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.9.5_next@14.2.10_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.10_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0_sass@1.72.0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-loading-skeleton@3.4.0_react@18.2.0/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Adapter/adapter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/AppIntegrations/modules/clientSideIntegrations.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/DemoNotice/modules/demoNoticeWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/StoreCodeRoute/storeCodeRoute.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/StoreSwitcher/storeSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/ToastContainer/toastContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/integrations/salesforce-chat/src/components/SalesforceChat/salesforceChat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RequestContextProvider"] */ "/vercel/path0/src/lib/context/request/request.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/lib/store/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/base/_global.scss");
