import React, { ReactElement } from 'react';
import { FiChevronRight as ChevronRightIcon } from 'react-icons/fi';
import { Field, useField } from 'formik';
import { useTranslations } from 'next-intl';
import Message from '@/components/FormMessage';

import type { ISelectProps } from './types';
import clsx from 'clsx';
import classes from './select.module.scss';

const Select = (props: ISelectProps): ReactElement => {
    const {
        classes: propClasses,
        dataCustomId,
        disabled,
        field,
        hasError,
        hideLabel = false,
        isCounter = false,
        isRequired,
        items,
        label,
        message,
        notice,
        validate,
        validateBeforeTouched,
        ...rest
    } = props;

    const [, meta] = useField(field);
    const t = useTranslations('field');

    const error = validateBeforeTouched ? meta.error : meta.touched && meta.error;
    const success = !error && meta.value;

    const selectClassName = clsx(
        classes.select,
        (error || hasError) && classes.error,
        meta.value && classes.hasValue,
        isCounter && classes.isCounter,
        !isRequired && !isCounter && classes.isPadded,
        success && classes.success,
    );

    const options = items.map(({ disabled, hidden, label, value, key = value }) => (
        <option disabled={disabled} hidden={hidden} key={key || value} value={value}>
            {label || (value != null ? value : '')}
        </option>
    ));

    return (
        <div>
            <div className={classes.wrapper}>
                <Field
                    {...rest}
                    aria-required={isRequired}
                    as={'select'}
                    className={selectClassName}
                    data-hj-suppress={true}
                    disabled={disabled}
                    field={field}
                    name={field}
                    validate={validate}
                >
                    {label && <option value="" />}
                    {options}
                </Field>
                {!hideLabel && (
                    <label className={classes.label} htmlFor={field}>
                        {label || field}
                    </label>
                )}
                <fieldset className={classes.fieldset} aria-hidden="true">
                    <legend className={!hideLabel || !isCounter ? classes.legend : classes.legendHidden}>
                        <span>{label || field}</span>
                    </legend>
                </fieldset>
                {!isRequired && !isCounter ? <sup className={classes.supplement}>{t('optional')}</sup> : null}
                {!disabled && <ChevronRightIcon className={classes.chevronIcon} />}
            </div>
            {error || message ? (
                <Message error={error || ''} className={classes.message} dataCustomId={dataCustomId}>
                    {message}
                </Message>
            ) : null}
            {notice && (
                <span className={classes.fieldNotice} data-custom-id={dataCustomId}>
                    {notice}
                </span>
            )}
        </div>
    );
};

Select.displayName = 'Select';

export default Select;
