import React from 'react';
import CartContextProvider from './cart/cart';
import CatalogContextProvider from './catalog/catalog';
import UserContextProvider from './user/user';
import ToastContextProvider from './toasts';
import { FacebookUserDataContextProvider } from '@/integrations/google-tag-manager/src/context/facebookUserDataContext';

/**
 * List of context providers that are required to run Next application
 *
 * @property {React.Component[]} contextProviders
 */
const contextProviders = [
    CartContextProvider,
    UserContextProvider,
    CatalogContextProvider,
    ToastContextProvider,
    FacebookUserDataContextProvider,
];

const ContextProvider = ({ children }) => {
    return contextProviders.reduceRight((memo, ContextProvider) => {
        return <ContextProvider>{memo}</ContextProvider>;
    }, children);
};

export default ContextProvider;
