import { handleActions } from 'redux-actions';
import actions from '@/lib/store/actions/user/actions';

export const name = 'user';

const initialState = {
    currentUser: {
        email: '',
        firstname: '',
        lastname: '',
    },
    getDetailsError: null,
    isGettingDetails: false,
    isInitialized: false,
    isLoginProcess: false,
    isResettingPassword: false,
    isSignedIn: false,
    resetPasswordError: null,
    token: null,
};

const reducerMap = {
    [actions.setToken]: (state, { payload }) => {
        return {
            ...state,
            isInitialized: true,
            isSignedIn: true,
            token: payload,
        };
    },
    [actions.clearToken]: (state) => {
        return {
            ...state,
            isInitialized: true,
            isSignedIn: false,
            token: null,
        };
    },
    [actions.getDetails.request]: (state) => {
        return {
            ...state,
            getDetailsError: null,
            isGettingDetails: true,
        };
    },
    [actions.getDetails.receive]: (state, { error, payload }) => {
        if (error) {
            return {
                ...state,
                getDetailsError: payload,
                isGettingDetails: false,
            };
        }

        return {
            ...state,
            currentUser: payload,
            getDetailsError: null,
            isGettingDetails: false,
        };
    },
    [actions.resetPassword.request]: (state) => ({
        ...state,
        isResettingPassword: true,
    }),
    // TODO: handle the reset password response from the API.
    [actions.resetPassword.receive]: (state, { error, payload }) => {
        if (error) {
            return {
                ...state,
                isResettingPassword: false,
                resetPasswordError: payload,
            };
        }

        return {
            ...state,
            isResettingPassword: false,
            resetPasswordError: null,
        };
    },
    [actions.reset]: () => {
        return {
            ...initialState,
            isInitialized: true,
            isSignedIn: false,
            token: null,
        };
    },
    [actions.setIsSignedIn]: (state) => {
        return {
            ...state,
            isInitialized: true,
            isSignedIn: true,
        };
    },
    [actions.setIsGuest]: (state) => {
        return {
            ...state,
            isInitialized: true,
            isSignedIn: false,
        };
    },
    [actions.setIsLoginProcess]: (state, { payload }) => {
        return {
            ...state,
            isLoginProcess: payload,
        };
    },
};

export default handleActions(reducerMap, initialState);
