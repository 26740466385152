'use client';

import React, { createContext, useContext } from 'react';
import bindActionCreators from '@/lib/store/utils/bindActionCreators';
import actions from '@/lib/store/actions/toast/actions';
import { connect } from 'react-redux';

const ToastContext = createContext();

const ToastContextProvider = ({ children, toastState }) => {
    return <ToastContext.Provider value={toastState}>{children}</ToastContext.Provider>;
};

const mapStateToProps = ({ toast }) => ({ toastState: toast });

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastContextProvider);

export const useToastContext = () => useContext(ToastContext);
