import { handleActions } from 'redux-actions';
import actions from '@/lib/store/actions/checkout/actions';

export const name = 'checkout';

const initialState = {
    allowedCountriesForCheckout: {},
    availableShippingMethods: [],
    billingAddress: null,
    billingAddressError: null,
    guestCustomerEmail: null,
    isCustomerEmailNew: true,
    isCustomerSubscribed: false,
    isInvoiceRequested: false,
    isNewCustomerAddress: false,
    isNewsletterChecked: false,
    isOrderProcessing: false,
    isRedirectToPaymentService: false,
    isSubmitting: false,
    orderError: null,
    paymentCode: '',
    paymentData: null,
    paymentMethodError: null,
    receipt: {
        order: {},
    },
    shippingAddress: {},
    shippingAddressError: null,
    shippingMethod: '',
    shippingMethodError: null,
    shippingTitle: '',
};

const reducerMap = {
    [actions.begin]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
    [actions.billingAddress.submit]: (state) => ({
        ...state,
        billingAddressError: null,
        isSubmitting: true,
    }),
    [actions.billingAddress.accept]: (state, { payload }) => {
        // Billing address can either be an object with address props OR
        // an object with a single prop, `sameAsShippingAddress`, so we need
        // to do some special handling to make sure the store reflects that.
        const newState = {
            ...state,
            isSubmitting: false,
        };
        if (payload.sameAsShippingAddress) {
            newState.billingAddress = {
                ...payload,
            };
        } else if (!payload.sameAsShippingAddress) {
            newState.billingAddress = {
                ...payload,
                street: [...payload.street],
            };
        }

        return newState;
    },
    [actions.billingAddress.reject]: (state, { payload }) => ({
        ...state,
        billingAddressError: payload,
        isSubmitting: false,
    }),
    [actions.getShippingMethods.receive]: (state, { error, payload }) => {
        if (error) {
            return state;
        }

        return {
            ...state,
            availableShippingMethods: payload.map((method) => ({
                ...method,
                code: method.carrier_code,
                title: method.carrier_title,
            })),
        };
    },
    [actions.shippingAddress.submit]: (state) => ({
        ...state,
        isSubmitting: true,
        shippingAddressError: null,
    }),
    [actions.shippingAddress.accept]: (state, { payload }) => ({
        ...state,
        isSubmitting: false,
        shippingAddress: {
            ...state.shippingAddress,
            ...payload,
            street: [...payload.street],
        },
    }),
    [actions.shippingAddress.reject]: (state, { payload }) => ({
        ...state,
        isSubmitting: false,
        shippingAddressError: payload,
    }),
    [actions.paymentMethod.submit]: (state) => ({
        ...state,
        isSubmitting: true,
        paymentMethodError: null,
    }),
    [actions.paymentMethod.accept]: (state, { payload }) => ({
        ...state,
        isSubmitting: false,
        paymentCode: payload.code,
        paymentData: payload.data,
    }),
    [actions.paymentMethod.reject]: (state, { payload }) => ({
        ...state,
        isSubmitting: false,
        paymentMethodError: payload,
    }),
    [actions.receipt.setOrder]: (state, { payload }) => ({
        ...state,
        receipt: {
            order: payload,
        },
    }),
    [actions.receipt.reset]: (state) => ({
        ...state,
        receipt: {
            ...initialState.receipt,
        },
    }),
    [actions.shippingMethod.submit]: (state) => ({
        ...state,
        isSubmitting: true,
        shippingMethodError: null,
    }),
    [actions.shippingMethod.accept]: (state, { payload }) => ({
        ...state,
        isSubmitting: false,
        shippingMethod: payload.carrier_code,
        shippingTitle: payload.carrier_title,
    }),
    [actions.shippingMethod.reject]: (state, { payload }) => ({
        ...state,
        isSubmitting: false,
        shippingMethodError: payload,
    }),
    [actions.order.submit]: (state) => ({
        ...state,
        isSubmitting: true,
        orderError: null,
    }),
    [actions.order.accept]: (state) => ({
        ...state,
        isSubmitting: false,
    }),
    [actions.order.reject]: (state, { payload }) => ({
        ...state,
        isSubmitting: false,
        orderError: payload,
    }),
    [actions.setIsInvoiceRequested]: (state, { payload }) => ({
        ...state,
        isInvoiceRequested: payload,
    }),
    [actions.setIsNewsletterChecked]: (state, { payload }) => ({
        ...state,
        isNewsletterChecked: payload,
    }),
    [actions.setIsNewCustomerAddress]: (state, { payload }) => ({
        ...state,
        isNewCustomerAddress: payload,
    }),
    [actions.setIsCustomerSubscribed]: (state, { payload }) => ({
        ...state,
        isCustomerSubscribed: payload,
    }),
    [actions.setIsCustomerEmailNew]: (state, { payload }) => ({
        ...state,
        isCustomerEmailNew: payload,
    }),
    [actions.setGuestCustomerEmail]: (state, { payload }) => ({
        ...state,
        guestCustomerEmail: payload,
    }),
    [actions.setAllowedCountriesForCheckout]: (state, { payload }) => ({
        ...state,
        allowedCountriesForCheckout: payload,
    }),
    [actions.setIsRedirectToPaymentService]: (state, { payload }) => ({
        ...state,
        isRedirectToPaymentService: payload,
    }),
    [actions.setIsOrderProcessing]: (state, { payload }) => ({
        ...state,
        isOrderProcessing: payload,
    }),
    [actions.reset]: () => initialState,
};

export default handleActions(reducerMap, initialState);
