import React, { forwardRef } from 'react';
import { IconProps } from '../types';

const Fallback = forwardRef<SVGSVGElement, IconProps>(({ height = 16, width = 16, ...props }, forwardedRef) => (
    <svg
        height={height}
        viewBox="0 0 16 16"
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
    >
        <path
            d="M 15.503906 10.78125 C 15.824219 9.914062 16 8.980469 16 8 C 16 7.019531 15.824219 6.085938 15.503906 5.21875 L 8 4.523438 L 0.496094 5.21875 C 0.175781 6.085938 0 7.019531 0 8 C 0 8.980469 0.175781 9.914062 0.496094 10.78125 L 8 11.476562 Z M 15.503906 10.78125 "
            fill="#ffffff"
        />
        <path
            d="M 8 16 C 11.441406 16 14.371094 13.828125 15.503906 10.78125 L 0.496094 10.78125 C 1.628906 13.828125 4.558594 16 8 16 Z M 8 16 "
            fill="#ffffff"
        />
        <path
            d="M 8 0 C 4.558594 0 1.628906 2.171875 0.496094 5.21875 L 15.503906 5.21875 C 14.371094 2.171875 11.441406 0 8 0 Z M 8 0 "
            fill="#ffffff"
        />
    </svg>
));

Fallback.displayName = 'Fallback';

export default Fallback;
