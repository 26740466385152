export const STORE_SWITCHER_LOCATION = {
    BOTTOM_RIGHT: 'BOTTOM_RIGHT',
    CENTER: 'CENTER',
    DEFAULT: 'DEFAULT',
    TOP_RIGHT: 'TOP_RIGHT',
};

export const STORE_SWITCHER_VARIANT = {
    DEFAULT: 'DEFAULT',
    GEOLOCATION: 'GEOLOCATION',
};
