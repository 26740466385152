import { handleActions } from 'redux-actions';
import actions from '../actions/toast';

const initialState = {
    toasts: new Map(),
};

const reducerMap = {
    [actions.add]: (state, { payload }) => {
        const nextToasts = new Map(state.toasts);
        const prevToast = nextToasts.get(payload.id);

        const isDuplicate = !!prevToast;
        let timestamp = payload.timestamp;
        if (isDuplicate) {
            // If this is a _new_ duplicate toast we need to clear the
            // previous timeout to prevent premature removal.
            globalThis.clearTimeout(prevToast.removalTimeoutId);

            // And to retain chronological order of addition, keep the
            // original timestamp.
            timestamp = prevToast.timestamp;
        }

        nextToasts.set(payload.id, {
            ...payload,
            isDuplicate,
            timestamp,
        });

        return {
            ...state,
            toasts: nextToasts,
        };
    },
    [actions.remove]: (state, { payload }) => {
        const nextToasts = new Map(state.toasts);

        const prevToast = nextToasts.get(payload.id);
        if (prevToast) {
            globalThis.clearTimeout(prevToast.removalTimeoutId);
        }

        nextToasts.delete(payload.id);

        return {
            ...state,
            toasts: nextToasts,
        };
    },
};

export default handleActions(reducerMap, initialState);
