import app from './app';
import cart from './cart';
import catalog from './catalog';
import checkout from './checkout';
import user from './user';
import toast from './toast';

const reducers = {
    app,
    cart,
    catalog,
    checkout,
    toast,
    user,
};

export default reducers;
