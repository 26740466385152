'use client';

import React, { ReactElement } from 'react';
import loadable from '@loadable/component';
import { useTranslations } from 'next-intl';

import { useAppContext } from '@/lib/context';
import Button from '@/components/Button';
import { removeWorkwearSuffix } from '@/utils/Workwear';
import { FlagFallbackIcon } from '@/icons/fallback';
import { STORE_SWITCHER_LOCATION } from '@/constants/storeSwitcher';

import type { ButtonProps } from '@/components/Button/button';
import classes from './styles/storeSwitcherTrigger.module.scss';

interface IStoreSwitcherTrigger {
    isFullWidth?: boolean;
    isSimple?: boolean;
    isSmall?: boolean;
    onClick?: () => void;
    triggerVariant?: ButtonProps['variant'];
}

const StoreSwitcherTrigger = ({
    isFullWidth,
    isSimple = false,
    isSmall,
    onClick,
    triggerVariant,
}: IStoreSwitcherTrigger): ReactElement => {
    const [
        { storeConfig },
        {
            actions: { setIsStoreSwitcherOpen },
        },
    ] = useAppContext();

    const t = useTranslations('shippingInformation');
    const { storeGroupName, storeName, websiteCode } = storeConfig;
    const countryCode = removeWorkwearSuffix(websiteCode) || 'fallback';

    const FlagIcon = loadable(() => import(`@/icons/flags/${countryCode}`), {
        fallback: <FlagFallbackIcon />,
    });

    return isSimple ? (
        <Button
            aria-label={`${storeGroupName} - ${storeName}`}
            className={classes.storeSwitcherButtonSimple}
            onClick={() => {
                setIsStoreSwitcherOpen({ isOpen: true, location: STORE_SWITCHER_LOCATION.TOP_RIGHT });
            }}
            type="button"
            variant={'trigger'}
        >
            {countryCode && <FlagIcon className={classes.storeSwitcherFlag} />}
            {storeName}
        </Button>
    ) : (
        <Button
            aria-label={`${storeGroupName} - ${storeName}`}
            className={classes.storeSwitcherButton}
            isFullWidth={isFullWidth}
            isSmall={isSmall}
            onClick={() => {
                if (onClick) {
                    onClick();

                    return;
                }

                setIsStoreSwitcherOpen({ isOpen: true, location: STORE_SWITCHER_LOCATION.CENTER });
            }}
            type="button"
            variant={triggerVariant || 'trigger'}
            dataCustomId="store-switcher-button"
        >
            {t('changeCountryLabel')}
            <FlagIcon className={classes.storeSwitcherFlag} />
        </Button>
    );
};

StoreSwitcherTrigger.displayName = 'StoreSwitcherTrigger';

export default StoreSwitcherTrigger;
