import { handleActions } from 'redux-actions';
import actions from '../actions/monetate';

const initialState = {
    cartDetails: null,
};

const reducerMap = {
    [actions.setCartDetails]: (state, { payload }) => ({
        ...state,
        cartDetails: payload,
    }),
    [actions.resetCartDetails]: (state) => ({
        ...state,
        cartDetails: null,
    }),
};

export default handleActions(reducerMap, initialState);
