import { createActions } from 'redux-actions';

const prefix = 'ADYEN';
const actionTypes = [
    'SET_CARD',
    'RESET_CARD',
    'SET_CHECKOUT',
    'RESET_CHECKOUT',
    'SET_DETAILS',
    'RESET_DETAILS',
    'SUBMIT',
    'RESET_SUBMIT',
    'ERROR',
    'RESET_ERROR',
    'ON_CHANGE',
];

export default createActions(...actionTypes, { prefix });
