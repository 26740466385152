import { useEffect, useRef } from 'react';
import { useInitSalesforceChat } from './useInitSalesforceChat';
import { cookieManager } from '@vaimo-int/one-trust';
import { ISalesforceConfig } from '@/integrations/salesforce-chat/src/components/SalesforceChat/types';

declare global {
    interface Window {
        embedded_svc?: any;
    }
}

interface IUseSalesforceChat extends Omit<ISalesforceConfig, 'customUrlsList'> {
    isEnabled: boolean;
}

export const useSalesforceChat = ({ isEnabled, ...props }: IUseSalesforceChat): void => {
    const { getSalesforceChatScript, initESW } = useInitSalesforceChat(props);
    const scripAlreadyLoadedRef = useRef<boolean>(false);

    const hideChatButton = () => {
        const salesforceChatButton = document.querySelector<HTMLDivElement>('.embeddedServiceHelpButton');
        if (salesforceChatButton) {
            salesforceChatButton.style.display = 'none';
        }
    };

    useEffect(() => {
        if (!isEnabled) {
            hideChatButton();

            return;
        }
        if (!scripAlreadyLoadedRef.current) {
            const salesforceChatScript = getSalesforceChatScript();
            if (!window.embedded_svc) {
                salesforceChatScript.addEventListener('load', () => {
                    initESW();
                });
                cookieManager.executeIfAllowed(() => {
                    globalThis.document.body.appendChild(salesforceChatScript);
                }, cookieManager.PrivacyGroupEnum.FUNCTIONAL);
            } else {
                salesforceChatScript.addEventListener('load', () => {
                    initESW();
                });
                const salesforceChatButton = document.querySelector<HTMLDivElement>('.embeddedServiceHelpButton');
                if (salesforceChatButton) {
                    salesforceChatButton.style.display = '';
                }
            }
            scripAlreadyLoadedRef.current = true;
        }
    }, [isEnabled]);

    // Salesforce script appends to the DOM a lot more scripts and elements including the button
    // It is not possible to remove the script in clean up effect
    // Thus we're managing button's visibility between pages while keeping the script in the body
    useEffect(() => {
        return () => {
            hideChatButton();
        };
    }, []);
};
